import { calculateAge } from "../common/date";



export const getUserInfo = async () => {
    return {
        home: {
            // heroImage: "img/hero/dark.jpg",
            heroMobileImage: "img-mobile",
            heroTitleName: "Edward Enrique Forero Aldana",
            heroDesignation: "Full stack developer",
            heroDescriptions: `Hi, I'm a Developer with more than ${calculateAge(new Date('2015-01-01'))} years of experience, proactive with initiative and committed to achieving my goals, both personal and work. Looking to expand and improve my knowledge and experiences day by day in order to make a better contribution to my environment.`,
            heroBtn: "more about me",
        },
        about: {
            avatar: "img/hero/img-mobile.jpg",
            personalInfo: [
                { meta: "first name", metaInfo: "Edward" },
                { meta: "last name", metaInfo: "Forero" },
                { meta: "Age", metaInfo: calculateAge(new Date('1989-10-22'))},
                { meta: "Nationality", metaInfo: "Venezuelan / Colombian" },
                // { meta: "Freelance", metaInfo: "Available" },
                { meta: "Address", metaInfo: "Perú" },
                { meta: "phone", metaInfo: "+51950351319" },
                { meta: "Email", metaInfo: "edward.efa@gmail.com" },
                // { meta: "Skype", metaInfo: " steve.milner" },
                { meta: "langages", metaInfo: "Spanish, French (basic), English (medium)" },
            ],
            achievements: [
                { title: calculateAge(new Date('2015-01-01')), subTitle1: "years of", subTitle2: "experience as software developer" },
                { title: calculateAge(new Date('2019-01-01')), subTitle1: "years of", subTitle2: "experience as a technical leader" },
                { title: calculateAge(new Date('2019-01-01')), subTitle1: "years of", subTitle2: "experience working in ecomerces" },
                { title: calculateAge(new Date('2017-10-01')), subTitle1: "years of", subTitle2: "experience implementing aws cloud" },
                // { title: "97", subTitle1: "completed", subTitle2: "projects" },
                // { title: "81", subTitle1: "Happy", subTitle2: "customers" },
                // { title: "53", subTitle1: "awards", subTitle2: "won" },
            ],
            skills: [
                { skillPercent: "55", skillName: "AWS" },
                { skillPercent: "20", skillName: "Firebase" },
                { skillPercent: "90", skillName: "DynamoDb" },
                { skillPercent: "75", skillName: "AWS SAM" },
                { skillPercent: "65", skillName: "AWS Serverless" },
                { skillPercent: "60", skillName: "JAVASCRIPT" },
                { skillPercent: "40", skillName: "REACT" },
                { skillPercent: "40", skillName: "REACT-NATIVE" },
                { skillPercent: "80", skillName: "NODEJS" },
                { skillPercent: "90", skillName: "PHP" },
                { skillPercent: "70", skillName: "Python" },
                { skillPercent: "60", skillName: "MongoDB" },
                { skillPercent: "90", skillName: "MySQL" },
                { skillPercent: "50", skillName: "PostgreSql" },
                { skillPercent: "45", skillName: "Flutter" },
            ],
            experience: [
                {
                  year: "2022 - Present",
                  position: " Software Engineer V",
                  compnayName: "Acid Labs",
                  details: ``,
                },
                {
                  year: "2021 - Present",
                  position: "  front-end developer (Part-time)",
                  compnayName: "Sistema S10 Perú",
                  details: `  CMS development tailored to the company using technologies such as React, Redux, hooks, Material-Ui, connection with SignalR.`,
                },
                {
                  year: "2019 - Present",
                  position: "  fullstack developer (freelance)",
                  compnayName: "QC Ingenieros SAC",
                  details: `  I work according to the requirements and needs of the company to improve or optimize the CMS system they have, one of the adjustments being to improve the use of the AWS emblem database (DynamoDB) since it was not being used correctly. Other technologies used: AWS Cloudformation, serverless, NodeJS, React, s3, cognito, rest Api.`,
                },
                {
                  year: "2021 - 2022",
                  position: " Analista desarrollado Full Stack",
                  compnayName: "Indra Perú",
                  details: `Development using technologies like NodeJs, serverless framework, AWS, ECS, DynamoDB, Lambda, StepFunctions, S3, ect.`,
                },
                {
                  year: "2017 - 2021",
                  position: "Technical Leader",
                  compnayName: "Platanitos Perú",
                  details: `Work team management, new developments and implementations for the company's tailored system, which includes publishing the stock of stores in real time in ecommerce, technologies such as AWS, Flutter, React, React-Native, MySQL, stored procedures , Python, PHP, HTML, CSS, JS, JQuery, PostgreSQL, PushNotifications, API-Rest, and others`,
                },
                {
                  year: "2021",
                  position: "App Francés (Play Store)",
                  compnayName: "Personal Project",
                  details: `Project carried out with Flutter (published) in order to be able to save the notes of the course carried out at the ILFI institute, with information that can be updated on demand using the Google cloud.`,
                },
                {
                  year: "2019",
                  position: "Form RIP",
                  compnayName: "Freelance requirement",
                  details: `Site made for a client from Argentina which consists of facilitating the sending of emails for companies whose sites are static, for this we used serverless technologies in the AWS cloud and React for the front-end.`,
                },
                {
                  year: "2015",
                  position: "Horse betting site",
                  compnayName: "Freelance requirement",
                  details: `Project carried out for a Venezuelan company whose purpose is to be able to carry out horse betting sales using several boxes and several branches (technologies: php, HTML, css, JS).`,
                },
            ],
            education: [
                {
                  year: "2021",
                  degree: "AWS",
                  institute: "AWS ",
                  details: ``,
                },
                {
                  year: "2019",
                  degree: "NodeJs",
                  institute: "Udemy",
                  details: ``,
                },
                {
                  year: "2021",
                  degree: "Flutter",
                  institute: "Udemy",
                  details: ``,
                },
                {
                  year: "2019",
                  degree: "React-Native",
                  institute: "Udemy",
                  details: ``,
                },
                {
                  year: "2012-unfinished",
                  degree: "Systems engineer",
                  institute: "UNEFA",
                  details: `8th semester`,
                },
                {
                  year: "2006",
                  degree: "Bachelor of Science",
                  institute: "TEOREMA",
                  details: ``,
                },
            ],
        },
        contact: {
            title: "Contact me!",
            message: `Feel free to get in touch with me. I am always open to
            discussing new projects, creative ideas or opportunities to
            be part of your visions.`,
            address: "San Juan de Miraflores, Lima, Perú",
            phone: "+519503511319",
            email: "edward.efa@gmail.com",
        },
        social: [
            {
              iconName: "fa fa-facebook",
              link: "https://www.facebook.com/edward.efa",
            }, 
            { 
                iconName: "fa fa-twitter", 
                link: "https://twitter.com/edward_efa" 
            }, 
            // {
            //   iconName: "fa fa-youtube",
            //   link: "https://www.youtube.com/",
            // }, 
            // { 
            //     iconName: "fa fa-dribbble", 
            //     link: "https://dribbble.com/" 
            // },
            { 
                iconName: "fa fa-linkedin", 
                link: "https://www.linkedin.com/in/edward-forero-aldana-227415176/" 
            },
        ] 

    }
};