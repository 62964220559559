import React from "react";
// import Preview from "../views/Preview";
// import HomeLight from "../views/all-home-version/HomeLight";
import HomeDark from "../views/all-home-version/HomeDark";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = (props) => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/about" render={(p) => <HomeDark {...p} {...props} />} />
          <Route exact path="/:page" render={(p) => <HomeDark {...p} {...props} />} />
          <Route exact path="/" render={(p) => <HomeDark {...p} {...props} />} />
          {/* <Route exact path="/" component={Preview} /> */}
          {/* <Route path="/home-light" component={HomeLight} /> */}
          {/* <Route path="/home-dark" component={HomeDark} /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
