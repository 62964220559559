export default (state, action) => {
    switch (action.type) {
      case "userData":
        return {
          ...state,
          ...action.payload,
        };
      case "apiCalled":
        return {
          ...state,
          apiCalled: action.payload
        };
      default:
        return state;
    }
};