import { createStore } from "redux";
// import rotateReducer from "./reducers/rotateReducer";
import userInformationReducer from "./reducers/userInformationReducer";

const stateData = {
    home: {},
    about: {},
    contact: {},
    porfolio: {},
    blog: {},
    apiCalled: false,
}

function configureStore(state = stateData) {
  return createStore(userInformationReducer, state);
}

export default configureStore;