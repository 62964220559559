import React, {useEffect, useState} from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/Hero";
import Index from "../../components/about/index";
import Address from "../../components/Address";
import Portfolio from "../../components/portfolio/Portfolio";
import Blog from "../../components/blog/Blog";
import Contact from "../../components/Contact";
import Social from "../../components/Social";

const menuItem = [
  { icon: "fa-home", menuName: "Home", location: ""  },
  { icon: "fa-user", menuName: "About", location: "/about" },
  // { icon: "fa-briefcase", menuName: "Portfolio", location: "/portfolio"  },
  { icon: "fa-envelope-open", menuName: "Contact", location: "/contact"  },
  // { icon: "fa-comments", menuName: "Blog", location: "/blog"  },
];

const HomeDark = (props) => {
  const {about} = props;
  const [optionSelected, setOptionSelected] = useState(0);

  useEffect(() => {
    if ( (!about || Object.keys(about).length === 0) && !props.apiCalled ) {
      props.setApiCalled();
      props.getUserDataAction();
    }
  }, []);

  useEffect(() => {
    for (let i = 0; i < menuItem.length; i++) {
      if ( props.history.location.pathname === menuItem[i].location && optionSelected !== i ) {
          setOptionSelected(i);
      }
    }
  }, [props.history.location.pathname]);

  const changeLocation = (i) => {
    setOptionSelected(i);
    props.history.push(menuItem[i].location);
  }
  
  return (
    <div className="yellow">
      {/* <div className="demo-sticker">
        <a href="/home-light">
          <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
        </a>
      </div> */}
      <Tabs selectedIndex={optionSelected} onSelect={changeLocation}>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once " >
            {menuItem.map((item, i) => {
              return (
                <Tab className={"icon-box "} key={i} >
                  <i className={`fa ${item.icon}`}></i>
                  <h2>{item.menuName}</h2>
                </Tab>
              );
            })}
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero {...props} />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          {/* About Content Starts */}
          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  ABOUT <span>ME</span>
                </h1>
                <span className="title-bg">Resume</span>
              </div>
              {/* End title */}
              <Index {...props} />
            </div>
          </TabPanel>
          {/* About Content Ends */}

          {/* Portfolio Content Starts */}
          {/* <TabPanel className="portfolio professional">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>portfolio</span>
              </h1>
              <span className="title-bg">works</span>
            </div>
            <div
              className="container grid-gallery main-content"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <Portfolio />
            </div>
          </TabPanel> */}

          {/* Contact Content Starts */}
          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                get in <span>touch</span>
              </h1>
              <span className="title-bg">contact</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                  {props?.contact?.title}
                  </h3>
                  <p className="open-sans-font mb-4">
                    {props?.contact?.message}
                  </p>
                  <Address {...props} />
                  {/* End Address */}

                  <Social {...props} />
                  {/* End Social */}
                </div>
                {/* Left Side Ends */}

                {/*  Contact Form Starts  */}
                <div className="col-12 col-lg-8">
                  <Contact {...props} />
                </div>
                {/*  Contact Form Ends */}
              </div>
            </div>
            {/* End .container */}
          </TabPanel>
          {/* Contact Content Ends */}

          {/* Blog Content Starts */}
          {/* <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>blog</span>
              </h1>
              <span className="title-bg">posts</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row pb-50">
                <Blog />
              </div>
            </div>
          </TabPanel> */}

        </div>
      </Tabs>
    </div>
  );
};

export default HomeDark;

