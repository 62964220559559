import React, { useEffect } from "react";
import { connect } from "react-redux";


import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
// import AnimatedCursor from "react-animated-cursor";

// import { startAction } from "./actions/startAction";
import { getUserDataAction, setApiCalled } from "./actions/userInformationAction";




const App = (props) => {
  // this for animation
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 160, 1"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      /> */}
      <ScrollToTop />
      <Routes {...props} />
    </>
  );
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  // startAction: () => dispatch(startAction),
  getUserDataAction: async (payload) => dispatch(await getUserDataAction(payload)),
  setApiCalled: (payload) => dispatch(setApiCalled(payload)),
});

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);

