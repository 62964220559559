import {getUserInfo} from './../api/general'

export const getUserDataAction = async () => {

  const payload = await getUserInfo();
  return {
    type: "userData",
    payload,
  }
}

export const setApiCalled = () => {

    return {
      type: "apiCalled",
      payload: true,
    }
}
